<template>
  
  <v-layout>
    {{ this.cpf }}
    <v-dialog v-model="landingPageOptions.memberGetMemberDrawer" scrollable max-width="920px">
      <v-card>
        <v-layout o-hidden>
          <v-layout avatar column>
            <v-flex px-4 py-2 simulation-father>
              <button class="close-modal" @click="landingPageOptions.memberGetMemberDrawer = false; stepper = 1">
                <i class="fal fa-times"></i>
              </button>
              <v-flex shrink simulation-header>
                <h3 class="color-theme-texto">
                  <i class="fal fa-link-alt mr-3 color-theme-secundaria"></i>
                  Indique amigos
                </h3>
                <p class="color-theme-texto">
                  Cadastre-se, indique o <strong>{{ campaign.name }}</strong> para os seus amigos e participe
                </p>
              </v-flex>
              <v-flex shrink form-simulation pb-0>
                <v-stepper v-model="stepper">
                  <v-stepper-header>
                    <v-stepper-step :complete="stepper > 1" step="1">Digite o seu CPF</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="stepper > 2" step="2">Cadastre-se</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">Link de Embaixador</v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <form @submit.prevent="nextStep2"
                        @keydown.enter="performClick('nextStep2Ref')">
                        <v-card
                          class="mb-5 d-flex justify-center align-center"
                          height="200px"
                        >
                        <div class="d-flex justify-center align-center">
                        
                        <InputText
                          textLabel="CPF"
                          type="text"
                          mask="###.###.###-##"
                          v-model="cpf"
                        />
                      </div>
                      </v-card>         
                        <v-btn
                          class="btn btn-primary"
                          :disabled="!isFormValid(stepper)"
                          :loading="loading"
                          ref="nextStep2Ref"
                          style="color: white"
                          type="submit"
                        >
                          Próximo
                        </v-btn>

                        <v-btn 
                        @click="landingPageOptions.memberGetMemberDrawer = false; clearSteps()"
                        flat>Cancelar</v-btn>
                    </form>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <form @submit.prevent="nextStep3"
                      @keydown.enter="performClick('nextStep3Ref')">
                      <v-card
                        class="mb-5"
                        height="300px"
                      >
                        <div class="d-flex flex-column justify-center align-center">
                          <p>Digite os seus dados para cadastro:</p>
                          <InputText
                            textLabel="Nome Completo"
                            type="text"
                            v-model="user.name"
                          />
                          <InputText
                            textLabel="Telefone"
                            type="text"
                            v-model="user.phone"
                          />
                          <InputText
                            textLabel="Email"
                            type="email"
                            v-model="user.email"
                          />
                        </div>
                    </v-card>
                      <v-btn
                        class="btn btn-primary"
                        ref="nextStep3Ref"
                        :disabled="!isFormValid(stepper)"
                        style="color: white"
                        type="submit"
                      >
                        Próximo
                      </v-btn>

                      <v-btn 
                      @click="landingPageOptions.memberGetMemberDrawer = false; clearSteps()"
                      flat>Cancelar</v-btn>
                    </form>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                      <v-card
                        class="mb-5"
                        height="200px"
                      >
                      <div class="d-flex flex-column justify-center align-center">
                        <h3>Olá, {{ user.name }}!</h3>
                        <p class="text-md-center">Copie o link abaixo e divulgue para seus contatos. As doações realizadas com o seu link serão contabilizadas para você! Muito obrigado.</p>
                        <div class="code-container"
                          @click="copyLink"
                        >
                          <p 
                          class="mb-0"
                          style="cursor: pointer;"
                          v-text="indicationLink"></p>
                          <i class="fal fa-copy pl-2"></i>
                        </div>
                        <div>
                          <v-btn class="mt-3 btn btn-primary"
                            style="color: white"
                            @click="share"
                          >Compartilhar</v-btn>
                        </div>
                      </div>
                    </v-card>

                      <v-btn 
                      @click="landingPageOptions.memberGetMemberDrawer = false; clearSteps()"
                      flat>Fechar</v-btn>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card>
      <v-divider></v-divider>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
// Services
import MemberGetMemberService from "@/scripts/services/memberGetMember.service.js"
import InputText from "@/components/input/InputText.vue";
import CommonHelper from "@/scripts/helpers/common.helper";

export default {
  components: {
    InputText
  },
  props: {
    landingPageOptions: false,
    campaign: Object
  },
  data() {
    return {
      memberGetMemberService: new MemberGetMemberService(),
      dialog: true,
      showButton: false,
      cpf: '',
      stepper: 1,
      cpfIsValid: false,
      loading: false,
      user: {
        name: '',
        phone: '',
        email: ''
      },
      code: '',
    };
  },
  computed: {
    indicationLink() {
      const regex = new RegExp(/[^/]*$/)
      return (window.location.href).replace(regex, '') + '?indicador=' + this.code
    },
    
  },
  methods: {
    checkCpf() {
      return CommonHelper.validateCPF(this.cpf)
    },
    async nextStep2() {
      this.loading = true;
      const data = await this.memberGetMemberService.getMemberGetMember(this.cpf)
      this.loading = false;
      if (data) {
        this.code = data.code;
        this.user.name = data.name
        this.stepper = 3;
      }
      else this.stepper = 2
    },
    async nextStep3() {
      this.loading = true
      this.user.cpf = this.cpf
      const data = await this.memberGetMemberService.createMemberGetMember(this.user)
      if (data) {
        this.loading = false;
        this.user = {};
        this.user.name = data.name
        this.code = data.code;
        this.stepper = 3;
      }
      
    },
    clearSteps() {
      this.stepper = 1;
      this.user = {};
      this.cpf = '';
    },
    copyLink() {
      navigator.clipboard.writeText(this.indicationLink);
      this.$store.commit("SET_SNACKBAR_SUCCESS_MESSAGE", 'Link de embaixador copiado com sucesso' || 'Ok');
    },
    isFormValid(step) {
      if (step == 1) {
        return this.checkCpf() && this.cpf
      }

      if (step == 2) {
        return  (this.user.name !== '') && (this.user.phone !== '') &&
        (this.user.email !== '') && (CommonHelper.validateEmail(this.user.email));
      }
    },
    performClick(ref) {
      this.$refs[ref].click()
    },
    share() {
      const shareData = {
        title: "Link de Embaixador",
        text: "Olá, faça uma doação através do link abaixo.",
        url:  this.indicationLink,
      };
      navigator.share(shareData)
    }
  }
    
};
</script>